import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate
import backgroundImage from '../../assets/50desconto.png';

const ModalImage = () => {
  const [showImage, setShowImage] = useState(true);
  const navigate = useNavigate(); // Use useNavigate para navegar entre as rotas

  const handleImageClick = (e) => {
    // Redirecionar para a URL desejada quando a imagem for clicada
    if (e.clientY > window.innerHeight / 2) {
      window.location.href = 'https://go.perfectpay.com.br/PPU38CNB92G?&utm_source=app&utm_medium=roleta';
    }
  };

  const handleCloseClick = () => {
    // Navegar de volta para a página /home quando o botão "X" for clicado
    navigate('/home');
  };

  const containerStyle = {
    display: showImage ? 'block' : 'none', // Controla a visibilidade da imagem de fundo
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    zIndex: 999, // Certifique-se de que o modal esteja na frente de outros elementos
  };

  const imageStyle = {
    backgroundImage: `url(${backgroundImage})`, // Defina a imagem como background
    backgroundSize: 'cover', // Ajuste o tamanho do background conforme necessário
    backgroundPosition: 'center', // Ajuste a posição do background conforme necessário
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '24px',
    color: '#fff',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div style={imageStyle} onClick={(e) => handleImageClick(e)}>
        <span style={closeButtonStyle} onClick={handleCloseClick}>
          &times;
        </span>
        <a href="https://go.perfectpay.com.br/PPU38CNB92G?&utm_source=app&utm_medium=roleta">
          {/* Você também pode adicionar conteúdo dentro do container */}
          {/* Por exemplo, um botão de redirecionamento */}
        </a>
      </div>
    </div>
  );
};

export default ModalImage;
