import React from "react";
import styled from "styled-components";

const IframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px; /* Adiciona um espaçamento ao redor do container do iframe */
  background-color: #fff; /* Defina uma cor de fundo se desejar */
  border-radius: 20px;
  box-shadow: 0px 0px 15px 2px #ff5b29; /* Adiciona uma sombra com a cor desejada */
  overflow: hidden; /* Garante que o conteúdo não vaze fora do border-radius */
`;

const IframeContainer = styled.iframe`
  width: 350px; /* Ajustado para 100% para preencher completamente o IframeWrapper */
  height: 400px; /* Defina a altura desejada para o iframe */
   border-radius: 20px;
  border: none; /* Remove a borda padrão do iframe */
`;

const IframeComponent = () => {
  return (
    <IframeWrapper>
      <IframeContainer
        src="https://record.partnersboomg.com/_zNe6BecKMYuVAv0U_Fv2nWNd7ZgqdRLk/2/?payload=poslogin"
        title="Live"
        scrolling="on"
      ></IframeContainer>
    </IframeWrapper>
  );
};

export default IframeComponent;
